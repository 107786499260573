<template>
  <section>
    <div class="content-header">
      <h2>Career resources for specific populations</h2>
    </div>
    <div class="content-wrapper">
      <p>It’s important to consider how systemic barriers limit career outcomes for specific populations. Here are some career resources for specific populations:</p>
      <ul>
        <li>University of British Columbia’s <a href="https://students.ubc.ca/career/career-resources/career-resources-historically-marginalized-students" target="_blank">Career resources for Indigenous students, students of colour, students with disabilities and LGBTQ2SIA+ students</a></li>
        <li>University of Toronto Mississauga’s <a href="https://www.utm.utoronto.ca/careers/about-us/services-resources" target="_blank">Resources for students</a>  (including international students, students with disabilities)</li>
      </ul>
      <p>Take the time to consult these resources so you can better support students who are coming up against these barriers.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '04',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
